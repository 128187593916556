/* eslint-disable no-template-curly-in-string */

export const mixed = {
  default: '${path} är inte giltig',
  required: 'Du måste ange ${path}',
  oneOf: '${path} måste vara en av följande värde: ${values}',
  notOneOf: '${path} kan inte vara ett av följande värde: ${values}',
};

export const string = {
  length: '${path} måste vara exakt ${length} bokstäver',
  min: '${path} måste vara minst ${min} bokstäver',
  max: '${path} får inte vara längre än ${max} bokstäver',
  matches: '${path} måste matcha följande: "${regex}"',
  email: 'Du måste ange en giltig ${path}',
  url: '${path} måste vara en giltig URL',
  trim: '${path} måste vara en trimmad sträng',
  lowercase: '${path} får endast vara gemener',
  uppercase: '${path} får endast vara versaler',
};

export const number = {
  min: '${path} måste vara större än eller lika med ${min}',
  max: '${path} måste vara mindre än eller lika med ${max}',
  less: '${path} måste vara mindre än ${less}',
  more: '${path} måste vara större än ${more}',
  notEqual: '${path} must be not equal to ${notEqual}',
  positive: '${path} måste vara ett positivt nummer',
  negative: '${path} måste vara ett negativt nummer',
  integer: '${path} måste vara ett heltal',
};

export const date = {
  min: '${path} måste vara senare än ${min}',
  max: '${path} måste vara tidigare än ${max}',
};

export const boolean = {};

export const object = {
  noUnknown: '${path} får inte ha nycklar som ej är specificerade i objektet',
};

export const array = {
  min: '${path} måste ha minst ${min} entiteter',
  max: '${path} måste ha mindre än eller exakt ${max} entiteter',
};

export const minPassword = 'Lösenord måste vara minst ${min} tecken';

const formsLocals = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};

export default formsLocals;
