import React, { useEffect, useState } from 'react';
import { Color, EditorValue, MessageBanner as SyntaxBanner } from '@gleerups/syntax4';
import { fetchMaintenanceInfo } from '../api';

export interface SystemMessage {
  description: EditorValue;
  endDate: string;
  isActive: boolean;
  isConfirmed: boolean;
  link?: string;
  startDate: string;
  title: string;
}

const isBefore7amToday = (dismissedTime: number) => {
  const dismissedDate = new Date(dismissedTime);
  const today = new Date();
  const sevenAM = new Date(today);
  sevenAM.setHours(7, 0, 0, 0); // Set time to 07:00:00.000

  return dismissedDate < sevenAM;
};

const SystemMessageBanner = () => {
  const [systemMessage, setSystemMessage] = useState<SystemMessage>();
  const [bannerDismissedInfo, setBannerDismissedInfo] = useState({
    dismissed: false,
    dismissedTime: new Date().getTime(),
  });
  const [hasRunOnce, setHasRunOnce] = useState(false);

  const fetchSystemMessage = async () => {
    try {
      const { data } = await fetchMaintenanceInfo();
      setSystemMessage(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const bannerInfo = window.localStorage.getItem('bannerDismissed');
    if (bannerInfo) {
      try {
        const parsedBannerInfo = JSON.parse(bannerInfo);
        if (
          typeof parsedBannerInfo === 'object' &&
          parsedBannerInfo !== null &&
          'dismissed' in parsedBannerInfo &&
          'dismissedTime' in parsedBannerInfo &&
          typeof parsedBannerInfo.dismissed === 'boolean' &&
          typeof parsedBannerInfo.dismissedTime === 'number'
        ) {
          setBannerDismissedInfo(parsedBannerInfo);
        } else {
          console.error('Invalid banner info structure:', parsedBannerInfo);
        }
      } catch (error) {
        console.error('Error parsing banner info:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (!hasRunOnce) {
      fetchSystemMessage();
    }

    setHasRunOnce(true);
  }, [hasRunOnce]);

  const handleDismissBanner = () => {
    const currentTime = new Date().getTime(); // Get the current timestamp in milliseconds
    window.localStorage.setItem(
      'bannerDismissed',
      JSON.stringify({
        dismissed: true,
        dismissedTime: currentTime,
      })
    );
    setBannerDismissedInfo({
      dismissed: true,
      dismissedTime: currentTime,
    });
  };

  if (
    systemMessage &&
    systemMessage.isActive &&
    systemMessage.isConfirmed &&
    // Check if the banner has been dismissed or if the dismissal was before 7am today
    (!bannerDismissedInfo.dismissed ||
      (bannerDismissedInfo.dismissed && isBefore7amToday(bannerDismissedInfo.dismissedTime)))
  ) {
    return (
      <SyntaxBanner
        title={systemMessage.title}
        description={systemMessage.description}
        link={systemMessage.link}
        handleDismissBanner={handleDismissBanner}
        ariaLabel="Stäng driftstörningsbannern"
        backgroundColor={Color.plannerOrange}
      />
    );
  }
  return null;
};

export default SystemMessageBanner;
