import { createGlobalStyle } from 'styled-components';
import { Color, Typography } from '@gleerups/syntax4';

export default createGlobalStyle`
/**
  * Fonts & sizes
  *
  * Base size set to browser default (usually 16px)
  */
html {
  font: 100%/1.7 'Open Sans', sans-serif;
  font-weight: normal;
  color: ${Color.grey800};
}

* {
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: inherit;
  border-radius: 0;
}

body {
  background: ${Color.white};
  height: 100vh;
  font-family: ${Typography.fontFamily};
  font-size: 1rem;
  line-height: 1.5; /* 24px */
}

a {
  transition: all ease-in-out 0.1s;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: underline;
  outline: 0;
}

header,
main {
  width: 100%;
  margin: 0 auto;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Typographic scale with a 1.25 scale factor and a line-height that fits a 4 point grid */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: ${Typography.fontFamily};
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

h1,
.h1 {
  font-size: 2,44140625rem;
  line-height: 1.4336; /* 56px */
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

h2,
.h2 {
  font-size: 1,953125rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.408; /* 44px */
}

h3,
.h3 {
  font-size: 1,5625rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.44; /* 36px */
}

h4,
.h4 {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.4; /* 28px */
}

h5,
.h5,
h6,
.h6 {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.6; /* 32px */
}

small,
.small {
  font-size: 0.8rem;
  line-height: 1.25;
}

p,
ul,
ol,
pre,
table,
blockquote {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
`;
