import apiBackend from './config/api-client';
import { apiClient } from '@gleerups/api-fetch';
import { validate } from 'uuid';
export { ApiFetchError } from '@gleerups/api-fetch';

const apiCoreUrl = '/core/api';
const coreApi = apiClient({
  apiUrl: apiCoreUrl,
  ignoreRedirect: true,
});

const createFormData = (fields: Record<string, string>) => {
  const formData = new FormData();
  const keys = Object.keys(fields);
  keys.forEach((key) => {
    formData.append(key, fields[key]);
  });
  return formData;
};

export const signinUser = (username: string, password: string) => {
  return coreApi('/v2/user/auth', {
    method: 'POST',
    body: createFormData({ username, password }),
  });
};

export const signinUserWithMFACode = (username: string, MFACode: string) => {
  return coreApi('/v2/user/auth/multi-factor', {
    method: 'POST',
    body: createFormData({ email: username, code: MFACode }),
  });
};

export const requestPasswordReset = (username: string) => {
  return coreApi('/v2/user/SendPassword', {
    method: 'POST',
    body: createFormData({ username }),
  });
};

export const resetPassword = ({
  password,
  confirmPassword,
  token,
}: {
  password: string;
  confirmPassword: string;
  token: string;
}) => {
  return coreApi('/v2/user/SetPassword', {
    method: 'POST',
    body: createFormData({ password, confirmPassword, token }),
  });
};

export const validateEmail = (email: string) => {
  const formData = new FormData();
  formData.append('email', email);

  return coreApi('/v2/user/free-trial/validate-email', {
    method: 'POST',
    body: formData,
  });
};

export const getUserType = async (licenseId: string) => {
  const response = await coreApi('/v2/customer/GetType', {
    method: 'POST',
    body: createFormData({ token: licenseId }),
  });

  if (typeof response === 'string') {
    console.error('unexpected response on /v2/customer/GetType', response);
    return { isKomvuxUser: false };
  }
  return { isKomvuxUser: response.data === 2 };
};

export const getStatusCode = (error: unknown): number | undefined => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return error?.response?.status;
};

interface UserStatusResponse {
  data?: {
    isFreeTrial?: boolean;
  };
}

export const getUserStatus = async (username: string) => {
  const response = await coreApi(`/v2/user/exists?username=${encodeURIComponent(username)}`);

  if (typeof response === 'string') {
    console.error('unexpected response on /v2/user/exists', response);
    return { isTrialUser: false };
  }

  const responseData = response as UserStatusResponse;
  return { isTrialUser: !!responseData.data?.isFreeTrial };
};

export const createUser = ({
  firstName,
  lastName,
  email,
  password,
  profession,
  phoneNumber,
  licenseId,
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  profession?: string;
  phoneNumber?: string;
  licenseId: string;
}) => {
  return coreApi('/v2/user/Create', {
    method: 'POST',
    body: createFormData({
      firstName,
      lastName,
      email,
      password,
      profession: profession || '5', // 5 is obvious haha, but that what the API wants
      phoneNumber: phoneNumber && phoneNumber.length > 0 ? phoneNumber : 'undefined',
      token: licenseId,
    }),
  });
};

export interface SchoolData {
  name: string;
  visitCity: string;
  schoolUnitCode: number;
}

export const searchSchool = async (searchTerm: string): Promise<SchoolData[] | undefined> => {
  const response = await coreApi(`/v2/schoolUnit/search?q=${searchTerm}&limit=6`, {
    method: 'GET',
  });

  if (typeof response === 'string') {
    console.error('unexpected response on /v2/schoolUnit/search', response);
    return;
  }

  return (response as { data: SchoolData[] }).data;
};

export const createTryoutUser = ({
  firstName,
  lastName,
  email,
  password,
  profession,
  phoneNumber,
  schoolUnitCode,
  trialId,
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  profession: string;
  phoneNumber?: string;
  schoolUnitCode: string;
  trialId?: string;
}) => {
  const tryoutData: Record<string, string> = {
    firstName,
    lastName,
    email,
    password,
    profession,
    phoneNumber: phoneNumber && phoneNumber.length > 0 ? phoneNumber : 'undefined',
    schoolUnitCode,
  };

  if (trialId) {
    const param = validate(trialId) ? 'uuidv4' : 'productId';
    tryoutData[param] = trialId;
  }

  return coreApi('/v2/user/ProvaGratis/Create', {
    method: 'POST',
    body: createFormData(tryoutData),
  });
};

export const upgradeUser = ({ username, licenseId }: { username: string; licenseId: string }) => {
  const formData = new FormData();

  formData.append('username', username);
  formData.append('token', licenseId);

  return coreApi('/v2/user/ProvaGratis/Upgrade', {
    method: 'POST',
    body: formData,
  });
};

export const verifyEmail = (token: string) => {
  return coreApi('/v2/user/ProvaGratis/Activate', {
    method: 'POST',
    body: createFormData({ token }),
  });
};

export const hasSkolonIntegration = async (schoolId: string) =>
  apiBackend.public.getIsCustomer(schoolId, { type: 'schoolUnitCode' });

export const fetchLoginView = async (slug: string, articleId: string) =>
  apiBackend.public.getLoginView(slug, articleId);

export const fetchEducationalStages = async () =>
  apiBackend.public.getEducationalStages({ filterShowInList: true });

export const fetchMaintenanceInfo = async () => apiBackend.public.getMaintenanceInfo();

export const setTermsAccepted = () =>
  coreApi('/v2/user/SetTermsAccepted', {
    method: 'POST',
  });
