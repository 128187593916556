// Display warning for unsupported browsers
import './windowGlobal';
import { GlobalStyles, globalColorsStyles as GlobalColorsStyles } from '@gleerups/syntax4';

import React from 'react';
import ReactDOM from 'react-dom';
import { analytics } from '@gleerups/analytics';

import './i18n';

import GlobalBaseStyles from './styles/GlobalBaseStyles';

import App from './App';
import { initErrorReporter } from './initErrorReporter';
import { IS_MOCK_MODE } from './mocks/constants';

initErrorReporter();
const basename = '/login';

analytics.init({
  appName: 'auth-ui',
  // when running in Cypress we need real analytic configuration since we test GA in Cypress
  env: window.Cypress && 'chi',
});

const render = () => {
  ReactDOM.render(
    <>
      <GlobalColorsStyles />
      <GlobalStyles />
      <GlobalBaseStyles />
      <App basename={basename} />
    </>,
    document.getElementById('root')
  );
};

if (IS_MOCK_MODE) {
  const deferRender = async () => {
    const { worker } = await import('./mocks/browserSetup');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start({
      serviceWorker: {
        url: '/login/mockServiceWorker.js',
      },
      onUnhandledRequest(request, print) {
        // Ignore requests to fetch static assets.

        const fileEndings = ['\\.jpg', '\\.svg', '\\.png', '\\.tsx', '\\.ts', '\\.js'];
        if (fileEndings.some((ending) => request.url.match(`\\w+${ending}`))) {
          return;
        }
        console.log('unhandled', request.url);
        // Otherwise, print a warning for any unhandled request.
        print.warning();
      },
    });
  };
  deferRender().then(render);
} else {
  render();
}
