/* eslint-disable react/jsx-no-useless-fragment */
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled, { StyleSheetManager } from 'styled-components';
import { CookieBanner } from '@gleerups/cookie-banner';
import { ErrorBoundary } from '@gleerups/error-reporter';
import { OverlayProvider } from '@gleerups/syntax4';
import SystemMessageBanner from '../SystemMessageBanner';
import Footer from '../Footer';

import { RoutePaths } from '../routes/routePaths';

import SignIn from '../SignIn';
import ResetPasswordRequest from '../ResetPasswordRequest';
import ResetPassword from '../ResetPassword';
import Tryout from '../Register/Tryout';
import CreateAccount from '../Register/CreateAccount';
import isPropValid from '@emotion/is-prop-valid';
import SamlRedirect from '../SamlRedirect';

const CookieBannerWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Layout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1;
`;

const App = ({ basename }: { basename: string }) => (
  <OverlayProvider style={{ height: '100%' }}>
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ErrorBoundary>
        <Router basename={basename}>
          <SamlRedirect />
          <Layout>
            <CookieBannerWrapper>
              <CookieBanner />
              <SystemMessageBanner />
            </CookieBannerWrapper>
            <Main>
              <Helmet titleTemplate="%s - Gleerups digitala läromedel">
                <html lang="sv" />
                <meta
                  name="description"
                  content="Gleerupsportal.se är samlingsplatsen för dina digitala läromedel. Här loggar du som lärare eller elev enkelt och snabbt in. Välkommen."
                />
              </Helmet>
              <Routes>
                <Route
                  path={RoutePaths.HOME}
                  element={
                    <Suspense fallback={<></>}>
                      <SignIn />
                    </Suspense>
                  }
                />
                <Route
                  path={RoutePaths.TRYOUT}
                  element={
                    <Suspense fallback={<></>}>
                      <Tryout />
                    </Suspense>
                  }
                />
                <Route
                  path={RoutePaths.RESET_PASSWORD_REQUEST}
                  element={
                    <Suspense fallback={<></>}>
                      <ResetPasswordRequest />
                    </Suspense>
                  }
                />
                <Route
                  path={RoutePaths.NEW_PASSWORD}
                  element={
                    <Suspense fallback={<></>}>
                      <ResetPassword />
                    </Suspense>
                  }
                />
                <Route
                  path={RoutePaths.REGISTER}
                  element={
                    <Suspense fallback={<></>}>
                      <CreateAccount />
                    </Suspense>
                  }
                />
                <Route
                  path={RoutePaths.VERIFY}
                  element={
                    <Suspense fallback={<></>}>
                      <SignIn />
                    </Suspense>
                  }
                />
                <Route path="*" element={<Navigate to={RoutePaths.HOME} replace />} />
              </Routes>
            </Main>
            <Footer />
          </Layout>
        </Router>
      </ErrorBoundary>
    </StyleSheetManager>
  </OverlayProvider>
);

// This implements the default behavior from styled-components v5
// See https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
const shouldForwardProp = (propName: string, target: unknown) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

export default App;
