import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type AuthStatus = 'unknown' | 'auth' | 'noauth';

const SamlRedirect = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<AuthStatus>('unknown');

  useEffect(() => {
    const isUserAuthenticated = async () => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 3000);

      try {
        const response = await fetch('/api/me', {
          credentials: 'include',
          signal: controller.signal,
        });
        clearTimeout(timeoutId);

        if (response?.status === 401) {
          setStatus('noauth');
        } else if (response && response.status >= 200 && response.status < 300) {
          setStatus('auth');
        } else {
          // eslint-disable-next-line no-console
          console.error(`Request failed. Status: ${response?.status}`);
        }
      } catch (e) {
        // Network error or timeout, assume user is not logged in
        setStatus('noauth');
        if (!controller.signal.aborted) {
          // eslint-disable-next-line no-console
          console.error(`Request failed. ${e}`);
        }
      }
    };

    isUserAuthenticated();
  }, []);

  useEffect(() => {
    const r = searchParams.get('r') || '';
    const base = `${window.location.protocol}//${window.location.host}`;

    if (status === 'auth') {
      if (r !== '') {
        window.location.href = `${base}/${r}${window.location.hash}`;
        return;
      }
      window.location.href = `${base}/min-sida/`;
      return;
    }
    if (status === 'noauth' && r !== '') {
      const url = new URL(r, base);
      const hasSaml = url.searchParams.has('saml');
      const hasIdp = url.searchParams.has('idp');
      if (hasSaml || hasIdp) {
        const saml = url.searchParams.get('saml');
        const idp = url.searchParams.get('idp');
        url.searchParams.delete('saml');
        url.searchParams.delete('idp');

        const pathname = url.pathname.startsWith('/') ? url.pathname.substring(1) : url.pathname;
        const restOfQs = url.searchParams.toString();
        const returnPath = restOfQs.length > 0 ? `${pathname}?${restOfQs}` : pathname;
        const encodedReturnPath = encodeURIComponent(returnPath);
        const provider = hasSaml ? `saml=${saml}` : `idp=${idp}`;
        window.location.href = `${base}/samlAuth.php?${provider}&return=${encodedReturnPath}`;
        return;
      }
    }
  }, [searchParams, status]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default SamlRedirect;
