import React from 'react';
import styled from 'styled-components';

import { Breakpoint, Color, Spacing } from '@gleerups/syntax4';

import gleerupsLogo from './gleerups_logo_white.svg';
import socialIconFacebook from './social_facebook.svg';
import socialIconLinkedIn from './social_linkedin.svg';
import socialIconMail from './social_mail.svg';
import socialIconYoutube from './social_youtube.svg';

const FooterContainer = styled.footer`
  color: #fff;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: ${Color.grey700};
  padding: ${Spacing.XS} ${Spacing.M};
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr minmax(min-content, 1fr) 1fr;

  @media (${Breakpoint.smallMax}) {
    position: relative;
  }

  @media (max-width: 81rem) {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
`;

const Link = styled.a`
  padding: ${Spacing.XS};
  color: #fff;
  opacity: 0.9;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-self: self-start;
  @media (max-width: 81rem) {
    justify-self: center;
  }
  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const LinkSocial = styled.a`
  margin: 0;
  justify-self: center;
  color: #fff;
  opacity: 0.9;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const ListItemLink = styled.a`
  color: #fff;
  opacity: 0.9;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  text-decoration: none;
  font-weight: lighter;
  font-size: 14px;
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: underline;
    opacity: 1;
  }
`;

const List = styled.ul`
  padding: 0 ${Spacing.XS};
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (${Breakpoint.mediumMax}) {
    flex-direction: column;
  }
`;

const ListSocial = styled.ul`
  padding: ${Spacing.XS};
  margin: 0;
  display: flex;
  justify-self: self-end;
  @media (max-width: 81rem) {
    justify-self: center;
  }
`;

const ListItem = styled.li`
  margin: 0 ${Spacing.M};
  list-style: none;
  padding: 4px 0;
`;

const ListItemSocial = styled.li`
  list-style: none;
  padding: 4px 0;
  margin-left: ${Spacing.L};
  &:nth-child(1) {
    margin-left: 0;
  }
`;

const Image = styled.img`
  display: block;
  width: 5rem;
`;

const ImageSocialIcon = styled.img`
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  height: 28px;
  width: 28px;
`;

const Footer = () => (
  <FooterContainer>
    <Link href="/login/" aria-hidden="true" tabIndex={-1}>
      <Image src={gleerupsLogo} alt="Gleerups logotyp" />
    </Link>
    <List>
      <ListItem>
        <ListItemLink href="https://www.gleerups.se/support/licensvillkor-digitala-laromedel">
          Licensvillkor
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink href="https://www.gleerups.se/support/integritetspolicy/gleerups-digitala-laromedel">
          Integritetspolicy
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink href="https://www.gleerups.se/support/tekniska-krav">
          Tekniska krav
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink href="/cookies/" aria-label="inställningar för kakor">
          Cookies
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink href="https://www.gleerups.se/support/fragor-och-svar">
          Frågor och svar
        </ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink href="https://www.gleerups.se/support/guider-och-filmer">Guider</ListItemLink>
      </ListItem>
      <ListItem>
        <ListItemLink href="https://www.gleerups.se/support">Support</ListItemLink>
      </ListItem>
    </List>
    <ListSocial>
      <ListItemSocial>
        <LinkSocial
          aria-label="anmälan till Gleerups nyhetsbrev"
          href="https://gleerups.powerappsportals.com/?source=newsletter"
        >
          <ImageSocialIcon src={socialIconMail} alt="" />
        </LinkSocial>
      </ListItemSocial>
      <ListItemSocial>
        <LinkSocial
          aria-label="Gleerups på facebook"
          href="http://www.facebook.com/pages/Gleerups-Utbildning-AB/139863120811"
        >
          <ImageSocialIcon src={socialIconFacebook} alt="" />
        </LinkSocial>
      </ListItemSocial>
      <ListItemSocial>
        <LinkSocial
          aria-label="Gleerups på linkedin"
          href="https://www.linkedin.com/company/gleerups-utbildning-ab"
        >
          <ImageSocialIcon src={socialIconLinkedIn} alt="" />
        </LinkSocial>
      </ListItemSocial>
      <ListItemSocial>
        <LinkSocial
          aria-label="Gleerups på youtube"
          href="http://www.youtube.com/user/gleerupsfilm/"
        >
          <ImageSocialIcon src={socialIconYoutube} alt="" />
        </LinkSocial>
      </ListItemSocial>
    </ListSocial>
  </FooterContainer>
);

export default Footer;
