/**
 * Returns true if we want to use MSW to intercept http request
 */
export const IS_MOCK_MODE = !!import.meta.env['VITE_MSW_MOCK_HANDLER'];

export const IS_NODE_ENV =
  typeof process !== 'undefined' && process.versions != null && process.versions.node != null;

/**
 * When running tests on node environment we must use absolute URLs
 * This function returns a prefix to the URL so that the URL becomes absolut.
 */
export const API_URL_PREFIX = IS_NODE_ENV ? window.location.href : '';
